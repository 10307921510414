import React from 'react'
import { Container } from 'react-bootstrap'

import Logo from 'components/Logo'
import Notification from 'components/Notification'
import './header.scss'

export default function Header(props) {
    return (
        <header>
            <Container>
                <Logo />
                <Notification />
            </Container>
        </header>
    )
}
