import React from "react";
// import TabContainer from "react-bootstrap/TabContainer";
// import Tabs from "react-bootstrap/Tabs";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import "./tabs-search.scss";

export default function TabsSearch() {
  return (
    <section id="tabSearch">
      <Tab.Container id="left-tabs-example" defaultActiveKey="second">
        <div className="nav-tabs">
          <Nav variant="pills" className="tabs-title">
            <Nav.Item>
              <Nav.Link eventKey="first">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.1"
                  height="16.1"
                  viewBox="0 0 16.1 16.1"
                >
                  <path
                    d="M97.667,102.4a2.437,2.437,0,0,0-2.434,2.435.338.338,0,1,0,.675,0,1.761,1.761,0,0,1,1.759-1.76.338.338,0,0,0,0-.675Z"
                    transform="translate(-92.238 -99.18)"
                    fill="#5c4c2c"
                  />
                  <path
                    d="M11.373,9.481A6.167,6.167,0,0,0,2.861.964a6.213,6.213,0,0,0-1.9,1.9,6.167,6.167,0,0,0,8.512,8.517A6.214,6.214,0,0,0,11.373,9.481Zm-3.2.589a4.381,4.381,0,0,1-5.9-5.9,4.409,4.409,0,0,1,1.9-1.9,4.38,4.38,0,0,1,5.9,5.9A4.409,4.409,0,0,1,8.168,10.07Z"
                    fill="#5c4c2c"
                  />
                  <path
                    d="M331.423,329.684l-3.561-3.554a7.338,7.338,0,0,1-1.9,1.9l3.561,3.554a1.341,1.341,0,1,0,1.9-1.9Z"
                    transform="translate(-315.716 -315.875)"
                  />
                </svg>
                色んな角度
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.1"
                  height="16.1"
                  viewBox="0 0 16.1 16.1"
                >
                  <path
                    d="M97.667,102.4a2.437,2.437,0,0,0-2.434,2.435.338.338,0,1,0,.675,0,1.761,1.761,0,0,1,1.759-1.76.338.338,0,0,0,0-.675Z"
                    transform="translate(-92.238 -99.18)"
                    fill="#5c4c2c"
                  />
                  <path
                    d="M11.373,9.481A6.167,6.167,0,0,0,2.861.964a6.213,6.213,0,0,0-1.9,1.9,6.167,6.167,0,0,0,8.512,8.517A6.214,6.214,0,0,0,11.373,9.481Zm-3.2.589a4.381,4.381,0,0,1-5.9-5.9,4.409,4.409,0,0,1,1.9-1.9,4.38,4.38,0,0,1,5.9,5.9A4.409,4.409,0,0,1,8.168,10.07Z"
                    fill="#5c4c2c"
                  />
                  <path
                    d="M331.423,329.684l-3.561-3.554a7.338,7.338,0,0,1-1.9,1.9l3.561,3.554a1.341,1.341,0,1,0,1.9-1.9Z"
                    transform="translate(-315.716 -315.875)"
                  />
                </svg>
                花の名前
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.1"
                  height="16.1"
                  viewBox="0 0 16.1 16.1"
                >
                  <path
                    d="M97.667,102.4a2.437,2.437,0,0,0-2.434,2.435.338.338,0,1,0,.675,0,1.761,1.761,0,0,1,1.759-1.76.338.338,0,0,0,0-.675Z"
                    transform="translate(-92.238 -99.18)"
                    fill="#5c4c2c"
                  />
                  <path
                    d="M11.373,9.481A6.167,6.167,0,0,0,2.861.964a6.213,6.213,0,0,0-1.9,1.9,6.167,6.167,0,0,0,8.512,8.517A6.214,6.214,0,0,0,11.373,9.481Zm-3.2.589a4.381,4.381,0,0,1-5.9-5.9,4.409,4.409,0,0,1,1.9-1.9,4.38,4.38,0,0,1,5.9,5.9A4.409,4.409,0,0,1,8.168,10.07Z"
                    fill="#5c4c2c"
                  />
                  <path
                    d="M331.423,329.684l-3.561-3.554a7.338,7.338,0,0,1-1.9,1.9l3.561,3.554a1.341,1.341,0,1,0,1.9-1.9Z"
                    transform="translate(-315.716 -315.875)"
                  />
                </svg>
                花の咲く時期
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fourth">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.1"
                  height="16.1"
                  viewBox="0 0 16.1 16.1"
                >
                  <path
                    d="M97.667,102.4a2.437,2.437,0,0,0-2.434,2.435.338.338,0,1,0,.675,0,1.761,1.761,0,0,1,1.759-1.76.338.338,0,0,0,0-.675Z"
                    transform="translate(-92.238 -99.18)"
                    fill="#5c4c2c"
                  />
                  <path
                    d="M11.373,9.481A6.167,6.167,0,0,0,2.861.964a6.213,6.213,0,0,0-1.9,1.9,6.167,6.167,0,0,0,8.512,8.517A6.214,6.214,0,0,0,11.373,9.481Zm-3.2.589a4.381,4.381,0,0,1-5.9-5.9,4.409,4.409,0,0,1,1.9-1.9,4.38,4.38,0,0,1,5.9,5.9A4.409,4.409,0,0,1,8.168,10.07Z"
                    fill="#5c4c2c"
                  />
                  <path
                    d="M331.423,329.684l-3.561-3.554a7.338,7.338,0,0,1-1.9,1.9l3.561,3.554a1.341,1.341,0,1,0,1.9-1.9Z"
                    transform="translate(-315.716 -315.875)"
                  />
                </svg>
                花の色
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <ListGroup className="angle">
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>山野草</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>池・湿地の花</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>海辺の花</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>春の七草</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>秋の七草</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>いい香り</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>ハーブ</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>野菜･果物</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>ケヤキなどの高木</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>巨木･銘木</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>温室･観葉植物</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>食中植物</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>雑草</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>実</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>葉っぱ</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>珍しい花</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>薬草(薬用植物)</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="link">
                    <span>紅葉がきれい</span>
                  </Button>
                </ListGroup.Item>
              </ListGroup>
            </Tab.Pane>

            <Tab.Pane eventKey="second">
              <p className="desc">該当する花の名前の頭文字を選択してください</p>
              <ListGroup>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>あ行</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>か行</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>さ行</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>た行</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>な行</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>は行</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>ま行</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>や行</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>ら･わ行</span>
                  </Button>
                </ListGroup.Item>
              </ListGroup>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <p className="desc">該当する月を詮索してください</p>
              <ListGroup className="season">
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>1月</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>2月</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>3月</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>4月</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>5月</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>6月</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>7月</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>8月</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>9月</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>10月</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>11月</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="primary">
                    <span>12月</span>
                  </Button>
                </ListGroup.Item>
              </ListGroup>
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
            <p className="desc">該当する花の色を詮索してください</p>
            <ListGroup className="colors">
                <ListGroup.Item>
                  <Button href="" variant="" className="white">
                    <span>白</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant=""  className="yellow">
                    <span>黄</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="" className="orange">
                    <span>橙</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="" className="pink">
                    <span>ピンク</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="" className="red">
                    <span>赤</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="" className="blue">
                    <span>青</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="" className="purple">
                    <span>紫</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="" className="green">
                    <span>緑</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="" className="brown">
                    <span>茶</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button href="" variant="" className="black">
                    <span>黒</span>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item className="last">
                  <Button href="" variant="">
                    <span>色いろいろ</span>
                  </Button>
                </ListGroup.Item>
              </ListGroup>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </section>
  );
}
