import React, { Component } from 'react'

import Navigation from 'components/Navigation'
import Header from 'components/Header'
import Footer from 'components/Footer'
import ListFlowers from 'components/ListFlowers'

export default class Flowers extends Component {
    render() {
        return (
            <>
                <Navigation {...this.props}></Navigation>
                <Header></Header>
                <div className="divider-header"></div>
                <ListFlowers></ListFlowers>
                <Footer></Footer>
            </>
        )
    }
}
