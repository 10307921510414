import React from 'react'

import IconGoogle from 'assets/images/icon/google.svg'
import IconFacebook from 'assets/images/icon/facebook.svg'
import Button from 'react-bootstrap/Button'
import "./sns-login.scss"

export default function SnsLogin() {
    return (
        <div className="sns-login">
            <Button variant="light">
                <img src={IconGoogle} alt="" />
            </Button>
            <Button variant="light">
                <img src={IconFacebook} alt="" />
            </Button>
        </div>
    )
}