import React, { Component } from "react";

import Navigation from "components/Navigation";
import Header from "components/Header";
import Footer from "components/Footer";
import { Container } from "react-bootstrap";

import IconCamera from "assets/images/icon/camera.svg";

import "../assets/scss/pages/upload.scss";

export default class Upload extends Component {
  render() {
    return (
      <>
        <Navigation {...this.props}></Navigation>
        <Header></Header>
        <div className="divider-header"></div>
        <section id="upload-image">
        <h4 className="heading">彼岸花の写真を登録する</h4>
          <div className="upload-box">
            <Container>
              <p>
                写真を選択<span>（20枚まで）</span>
              </p>
              <div className="list">
                <div className="item">
                    <div>

                    </div>
                    <span>1</span>
                    <img src={IconCamera} alt="" className="camera"/>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>2</span>
                </div>
                <div className="item">
                <div>
                        
                        </div>
                    <span>3</span>
                </div>
                <div className="item">
                <div>
                        
                        </div>
                    <span>4</span>
                </div>
                <div className="item">
                <div>
                        
                        </div>
                    <span>5</span>
                </div>
                <div className="item">
                <div>
                        
                        </div>
                    <span>6</span>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>7</span>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>8</span>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>9</span>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>10</span>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>11</span>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>12</span>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>13</span>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>14</span>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>15</span>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>16</span>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>17</span>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>18</span>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>19</span>
                </div>
                <div className="item">
                    <div>
                        
                    </div>
                    <span>20</span>
                </div>
              </div>
            </Container>
          </div>
        </section>
        <Footer></Footer>
      </>
    );
  }
}
