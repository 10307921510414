import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import LogoSplash from "components/LogoSplashScreen";

import "../assets/scss/pages/splash-screen.scss";

export default class SplashScreen extends Component {
  render() {
    return (
      <section id="splash-screen">
        <Fade bottom>
          <LogoSplash />
        </Fade>
      </section>
    );
  }
}
