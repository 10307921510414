import React from "react";
import ProfileImage from "assets/images/icon/avatar.svg";
import IconFlower from "assets/images/icon/flower.svg";
import IconCamera from "assets/images/icon/camera.svg";
import { Container } from "react-bootstrap";
import './name-member.scss'

export default function NameMember() {
  return (
    <section id="nameMember">
      <Container>
        <div className="box">
          <div className="item left-side">
            <div className="profile-img">
              <img src={ProfileImage} alt="" title="" />
            </div>
            <div className="name">
              <h6>こいけなつみ</h6>
              <span>2021年11月16日登録</span>
            </div>
          </div>
          <div className="item right-side">
            <div>
              <img src={IconFlower} alt="" title="" />
              <span>296/2123</span>
            </div>
            <div>
              <img src={IconCamera} alt="" title="" className="camera"/>
              <span>4162</span>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
