import React, { Component } from 'react'
import Logo from 'components/Logo'
import LoginForm from 'components/LoginForm'
import SnsLogin from 'components/SnsLogin'
import { Container } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'

import "../assets/scss/pages/login.scss";

export default class Login extends Component {
    render() {
        return (
            <section id="login">
                <Container>
                    <Logo />
                    <LoginForm />
                    <SnsLogin />
                    <div className="last">
                        <span>アカウントお持ちでない方はこちら</span><br/>
                        <Button href="/sign-up" className="" variant="link">
                        新規登録
                        </Button>
                    </div>
                </Container>
            </section>
        )
    }
}
