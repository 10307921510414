import React from "react";
import Button from "react-bootstrap/Button";
import IconQuestion from 'assets/images/icon/question.svg'

import './footer.scss'

export default function Footer() {
  return (
    <footer>
      <div>
        <span>Special Thanks</span>
        <Button
          className=""
          href="https://www.hana300.com/"
          target="_blank"
          variant="link"
        >
          季節の花300
        </Button>
      </div>
      <div className="last">
      <Button
          className=""
          type="link"
          href="/contact"
          variant=""
        >
          お問合せ
        </Button>
      <Button
          className=""
          type="link"
          href="/terms-of-service"
          variant=""
        >
          遊び方 <img src={IconQuestion} alt="" />
        </Button>
      </div>
    </footer>
  );
}
