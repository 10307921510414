import React from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal'
import "./login-form.scss"

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered 
      className="login-error"
    >
      <Modal.Body>
        <h4>ログイン<br/>できませんでした</h4>
        <p>
        ーザー名、またはパスワードがただしくありません。もう一度入力してください。
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={props.onHide}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function LoginForm() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <Form className="login-form">
      <FloatingLabel
        controlId="floatingInput"
        label="ユーザー名"
        className="mb-3"
      >
        <Form.Control type="text" placeholder="ユーザー名" />
      </FloatingLabel>
      <FloatingLabel controlId="floatingPassword" label="パスワード">
        <Form.Control type="password" placeholder="パスワード" />
      </FloatingLabel>
      <Button variant="primary" className="mt-3" onClick={() => setModalShow(true)}>
      ログイン
      </Button>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Form>
  );
}
