import React from 'react'
import Brand from "assets/images/logo/logo.svg";
import "./Logo.scss"

export default function Logo() {
    return (
        <div className="logo"> 
            <img src={Brand} alt="Pocket Flower Go" title="Pocket Flower Go" />
        </div>
    )
}
