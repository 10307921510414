import React from 'react'
import { createBrowserHistory } from "history";
import { Router, Switch, Route } from 'react-router-dom'

import 'assets/scss/style.scss';
import SplashScreen from "pages/SplashScreen";
import Login from 'pages/Login'
import SignUp from 'pages/SignUp'
import MyPage from 'pages/MyPage'
import Home from 'pages/Home'
import Flowers from 'pages/Flowers'
import Member from 'pages/Member'
import Upload from 'pages/Upload'
import TermsOfService from 'pages/TermsOfService'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import NotFound from "pages/404";

const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

function App() {
  return (
    <div className="App">
      <Router history={history} basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/splash-screen" component={SplashScreen} />
          <Route path="/login" component={Login} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/mypage" component={MyPage} />
          <Route path="/flowers" component={Flowers} />
          <Route path="/member" component={Member} />
          <Route path="/upload" component={Upload} />
          <Route path="/terms-of-service" component={TermsOfService} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
