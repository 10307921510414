import React, { createRef} from "react";
import { MapContainer, Marker, Popup, TileLayer, FeatureGroup } from 'react-leaflet';

export class SimpleMap extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.mapRef = createRef();
    this.groupRef = createRef();
  }

  handleClick() {
    const map = this.mapRef.current.leafletElement;
    const group = this.groupRef.current.leafletElement;
    map.fitBounds(group.getBounds());
  }


  render() {
    return (
      <div>
        <MapContainer
          style={{ height: "450px", width: "100%" }}
          center={this.props.center}
          zoom={this.props.zoom}
          ref={this.mapRef}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
          <FeatureGroup ref={this.groupRef}>
            {this.props.locations.map(location => (
              <Marker
                key={location.images}
                position={{ lat: location.lat, lng: location.lng }}
              >
                <Popup>
                  <span>
                    <img src={location.images} />
                  </span>
                </Popup>
              </Marker>
            ))}
          </FeatureGroup>
        </MapContainer>
      </div>
    );
  }
}

export default SimpleMap