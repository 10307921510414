
import React, { Component } from 'react'

import './profile-picture.scss'

const ImgUpload =({
    onChange,
    src,
  })=>{
    return(
      <label for="photo-upload" className="custom-file-upload fas">
        <div className="img-wrap img-upload" >
          <img for="photo-upload" src={src} alt=""/>
        </div>
        <input id="photo-upload" type="file" onChange={onChange}/> 
      </label>
    );
  }
        
  const Edit =({
    onSubmit,
    children,
  })=>{
    return(
      <div className="card">
          {children}
      </div>
    );
  }

export default class ProfilePicture extends Component {
    constructor(props) {
        super(props);
         this.state = {
           file: '',
           imagePreviewUrl: 'https://www.hamazo.tv/img/template_img/assets/img/blank-profile.svg',
           active: 'edit'
        };
      }
      photoUpload (e) {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result
          });
        }
        reader.readAsDataURL(file);
      }

    render() {
        const {imagePreviewUrl} = this.state;
     return (
       <div className="edit-pic mb-3">
         <Edit onSubmit={(e)=>this.handleSubmit(e)}>
               <ImgUpload onChange={(e)=>this.photoUpload(e)} src={imagePreviewUrl}/>
             </Edit>
       </div>
       )
    }
}
