import React, { Component } from 'react'
import Logo from 'components/Logo'
import SignUpForm from 'components/SignUpForm'
import SnsLogin from 'components/SnsLogin'
import { Container } from 'react-bootstrap'

import "../assets/scss/pages/sign-up.scss";

export default class SignUp extends Component {
    render() {
        return (
            <section id="sign-up">
                <Container>
                    <Logo />
                    <p>アカウントを作成</p>
                    <SignUpForm />
                    <SnsLogin />
                </Container>
            </section>
        )
    }
}
