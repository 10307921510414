import React from 'react'

import IconComingSoon from 'assets/images/comingsoon.svg'
import './comingsoon.scss'

export default function ComingSoon() {
    return (
        <section id="comingsoon">
            <div className="icon">
                <img src={IconComingSoon} alt="" />
            </div>
            <h4>只今、準備中です。</h4>
            <div className="desc">
                <p>現在ページの準備を進めています。</p>
                <p>公開まで暫くお待ちください。</p>
            </div>
        </section>
    )
}
