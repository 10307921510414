import React, { Component } from 'react'

import Navigation from 'components/Navigation'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Ranking from 'components/Ranking'
import Button from "react-bootstrap/Button";

import "../assets/scss/pages/home.scss";

export default class Home extends Component {
    render() {
        return (
            <>
                <Navigation {...this.props}></Navigation>
                <Header></Header>
                <div className="divider-header"></div>
                <section id="home">
                    <Ranking></Ranking>
                    <div className="btnBox">
                        <Button href="/flowers" variant="primary">
                            新規登録 花情報
                        </Button>
                    </div>
                </section>
                <Footer></Footer>
            </>
        )
    }
}
