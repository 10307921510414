import React from "react";

import { withRouter } from "react-router-dom";

import Button from "react-bootstrap/Button";
import IconError from 'assets/images/error.svg'
import "../assets/scss/pages/error404.scss";

function NotFound(props) {
  return (
      <>
        <section id="error404">
        <div className="icon">
                <img src={IconError} alt="" />
            </div>
            <h4>ご指定のページが<br/>見つかりませんでした。</h4>
            <Button
              className="btn mt-5"
              type="button"
              onClick={() => props.history.goBack()}
              isLight
            >
              私を連れ戻す
            </Button>
        </section>
      </>
  );
}

export default withRouter(NotFound);
