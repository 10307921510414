import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ja from 'date-fns/locale/ja';
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";

import ProfilePicture from 'components/ProfilePicture'
import "react-datepicker/dist/react-datepicker.css";
import "./signup-form.scss";

export default function SignUpForm() {
  registerLocale('ja', ja)
  const [startDate, setStartDate] = useState(new Date());
  const years = range(1970, getYear(new Date()) + 1, 1);
  const months = [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
  ];

  return (
      <Form className="signup-form">
        <ProfilePicture/>
        <FloatingLabel
          controlId="floatingInput"
          label="ユーザー名"
          className="mb-3"
        >
          <Form.Control type="text" placeholder="pocket flower go" />
        </FloatingLabel>
        <FloatingLabel
          controlId="floatingInput"
          label="メールアドレス"
          className="mb-3"
        >
          <Form.Control type="email" placeholder="name@example.com" />
        </FloatingLabel>
        <FloatingLabel controlId="floatingPassword" label="パスワード">
          <Form.Control type="password" placeholder="パスワード" />
        </FloatingLabel>
        <FloatingLabel
          controlId="floatingPassword"
          label="パスワード確認"
          className="mt-3 mb-3"
        >
          <Form.Control type="password" placeholder="パスワード確認" />
        </FloatingLabel>
        <DatePicker
        locale="ja"
        placeholder="生年月日"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
        }) => (
          <div className="cldr">
            <div>
            生年月日を選択
            </div>
            <div className="flex-date">
            
            <div className="select-box">
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
  
            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            </div>
          </div>
          </div>
          
        )}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        className="form-control mb-3"
      />
    {['radio'].map((type) => (
      <div key={`inline-${type}`} className="mb-3 flex-between px-2">
        <Form.Check
          inline
          label="男性"
          name="group1"
          type={type}
          id={`inline-${type}-1`}
        />
        <Form.Check
          inline
          label="女性"
          name="group1"
          type={type}
          id={`inline-${type}-2`}
        />
      </div>
    ))}
    <FloatingLabel
          controlId="floatingInput"
          label="ご住所"
          className="mb-3"
        >
          <Form.Control type="text" placeholder="ご住所" />
        </FloatingLabel>
        
        <Button variant="primary" type="submit" className="mt-3">
        登録する
        </Button>
      </Form>
    );

  
}