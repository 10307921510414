import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Badge from 'react-bootstrap/Badge';
import Bell from "assets/images/icon/bell.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./notification.scss"

export default function Notification(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="notification">
        <Button className="" href="#" variant="" onClick={handleShow}>
      <img src={Bell} alt="Bell" title="Bell"/>
      <Badge bg="">2</Badge>
    </Button>
    </div>
    <Offcanvas show={show} onHide={handleClose} placement="end">
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>Activity</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body></Offcanvas.Body>
  </Offcanvas>
    </>
  );
}
