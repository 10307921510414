import React, { useState } from "react";

import Table from "react-bootstrap/Table";
import Collapse from 'react-bootstrap/Collapse'

import IconCamera from 'assets/images/icon/camera.svg'
import IconRanking1 from 'assets/images/icon/ranking1.svg'
import IconRanking2 from 'assets/images/icon/ranking2.svg'
import IconRanking3 from 'assets/images/icon/ranking3.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import Button from "react-bootstrap/Button";

import "./ranking.scss";


export default function Ranking() {
  const [open, setOpen] = useState(false);
  return (
    <section id="ranking">
      <div className="box">
        <h3 className="heading">昨日のトップ５</h3>
        <Table>
          <tbody>
            <tr>
              <td>
                <div>
                  <div className="position">
                    <img src={IconRanking1} alt="" />
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </div>
              </td>
              <td>さくら</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>121</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                <div className="position">
                    <img src={IconRanking2} alt="" />
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>ハクモクレン</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>87</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                <div className="position">
                    <img src={IconRanking3} alt="" />
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="down"/>
                  </span>
                </div>
              </td>
              <td>レンギョウ</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>43</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    4
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>たんぽぽ</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>22</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    5
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>なのはな</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>15</span>
                  </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <Collapse in={open}>
        <div id="example-collapse-text">
        <Table>
          <tbody>
            <tr>
              <td>
                <div>
                  <div className="position">
                    6
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </div>
              </td>
              <td>さくら</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>121</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                <div className="position">
                    7
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>ハクモクレン</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>87</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                <div className="position">
                    8
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="down"/>
                  </span>
                </div>
              </td>
              <td>レンギョウ</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>43</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    9
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>たんぽぽ</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>22</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    10
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>なのはな</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>15</span>
                  </div>
              </td>
            </tr>
          </tbody>
        </Table>
        </div>
      </Collapse>
        <Button href="" variant="" onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}>
        トップ50を見る
        <span><FontAwesomeIcon icon={faCaretDown} /></span>
        </Button>
      </div>
      <div className="box">
        <h3 className="heading">昨日のトップ５</h3>
        <Table>
          <tbody>
            <tr>
              <td>
                <div>
                  <div className="position">
                    <img src={IconRanking1} alt="" />
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </div>
              </td>
              <td>さくら</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>121</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                <div className="position">
                    <img src={IconRanking2} alt="" />
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>ハクモクレン</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>87</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                <div className="position">
                    <img src={IconRanking3} alt="" />
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="down"/>
                  </span>
                </div>
              </td>
              <td>レンギョウ</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>43</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    4
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>たんぽぽ</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>22</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    5
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>なのはな</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>15</span>
                  </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <Collapse in={open}>
        <div id="example-collapse-text">
        <Table>
          <tbody>
            <tr>
              <td>
                <div>
                  <div className="position">
                    6
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </div>
              </td>
              <td>さくら</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>121</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                <div className="position">
                    7
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>ハクモクレン</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>87</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                <div className="position">
                    8
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="down"/>
                  </span>
                </div>
              </td>
              <td>レンギョウ</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>43</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    9
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>たんぽぽ</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>22</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    10
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>なのはな</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>15</span>
                  </div>
              </td>
            </tr>
          </tbody>
        </Table>
        </div>
      </Collapse>
        <Button href="" variant="" onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}>
        トップ50を見る
        <span><FontAwesomeIcon icon={faCaretDown} /></span>
        </Button>
      </div>
      <div className="box">
        <h3 className="heading">1ヶ月のトップ10</h3>
        <Table>
          <tbody>
            <tr>
              <td>
                <div>
                  <div className="position">
                    <img src={IconRanking1} alt="" />
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </div>
              </td>
              <td>さくら</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>121</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                <div className="position">
                    <img src={IconRanking2} alt="" />
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>ハクモクレン</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>87</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                <div className="position">
                    <img src={IconRanking3} alt="" />
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="down"/>
                  </span>
                </div>
              </td>
              <td>レンギョウ</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>43</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    4
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>たんぽぽ</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>22</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    5
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>なのはな</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>15</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    6
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>なのはな</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>15</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    7
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>なのはな</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>15</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    8
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>なのはな</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>15</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    9
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>なのはな</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>15</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    10
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>なのはな</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>15</span>
                  </div>
              </td>
            </tr>

          </tbody>
        </Table>
        <Collapse in={open}>
        <div id="example-collapse-text">
        <Table>
          <tbody>
            <tr>
              <td>
                <div>
                  <div className="position">
                    11
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </div>
              </td>
              <td>さくら</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>121</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                <div className="position">
                    12
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>ハクモクレン</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>87</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                <div className="position">
                    13
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="down"/>
                  </span>
                </div>
              </td>
              <td>レンギョウ</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>43</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    14
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>たんぽぽ</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>22</span>
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <div>
                <div className="position">
                    15
                  </div>
                  <span>
                  <FontAwesomeIcon icon={faArrowRight} className="up" />
                  </span>
                </div>
              </td>
              <td>なのはな</td>
              <td>
                  <div>
                    <img src={IconCamera} alt="" />
                    <span>15</span>
                  </div>
              </td>
            </tr>
          </tbody>
        </Table>
        </div>
      </Collapse>
        <Button href="" variant="" onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}>
        トップ50を見る
        <span><FontAwesomeIcon icon={faCaretDown} /></span>
        </Button>
      </div>
    </section>
  );
}
