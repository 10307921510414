import React from "react";

import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import CardGroup from "react-bootstrap/CardGroup";

import IconSortBy from "assets/images/icon/sort-by.svg";
import IconSearch from "assets/images/icon/search-secondary.svg";
import Flower1 from "assets/images/flower/flower1.jpg";
import Flower2 from "assets/images/flower/flower2.jpg";
import Flower3 from "assets/images/flower/flower3.jpg";
import Flower4 from "assets/images/flower/flower4.jpg";
import Flower5 from "assets/images/flower/flower5.jpg";

import "./list-flower.scss";

export default function ListFlowers() {
  return (
    <section id="list-flowers">
      <div class="container">
        <p className="collection">
          コレクション (<span>156</span>)
        </p>
        <div class="search-box">
          <Form action="" className="has-search">
            <div>
              <img src={IconSearch} alt="" />
              <input type="text" class="form-control" placeholder="検索" />
            </div>
          </Form>
          <div className="sort-by">
            <Button variant="link">
              <img src={IconSortBy} alt="" />
            </Button>
          </div>
        </div>
        <CardGroup>
          <Card>
            <Card.Img variant="top" src={Flower1} />
            <Card.Body>
              <span>2021.06.01</span>
              <Card.Title>蝋梅</Card.Title>
              <Card.Text>
                ここにメモの文章が入ります。ここにメモの文章が入ります。
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={Flower2} />
            <Card.Body>
              <span>2021.06.01</span>
              <Card.Title>蓮華草</Card.Title>
              <Card.Text>
                ここにメモの文章が入ります。ここにメモの文章が入ります。
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={Flower3} />
            <Card.Body>
              <span>2021.06.01</span>
              <Card.Title>桐</Card.Title>
              <Card.Text>
                ここにメモの文章が入ります。ここにメモの文章が入ります。
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={Flower4} />
            <Card.Body>
              <span>2021.06.01</span>
              <Card.Title>藤袴</Card.Title>
              <Card.Text>
                ここにメモの文章が入ります。ここにメモの文章が入ります。
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={Flower5} />
            <Card.Body>
              <span>2021.06.01</span>
              <Card.Title>蔓梅擬</Card.Title>
              <Card.Text>
                ここにメモの文章が入ります。ここにメモの文章が入ります。
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
    </section>
  );
}
