import React, { Component } from 'react'

import Navigation from 'components/Navigation'
import Header from 'components/Header'
import NameMember from 'components/NameMember'
import TabsSearch from 'components/TabsSearch'
import Map from 'components/Map'
import Footer from 'components/Footer'

const locations = [
    {
      images: "https://www.hana300.com/agapan24.jpg",
      lat: 34.742930,
      lng: 137.692706
    },
    {
      images: "https://www.hana300.com/icyou0123.jpg",
      lat: 34.770233,
      lng: 137.687386
    },
    {
      images: "https://www.hana300.com/asagao73.jpg",
      lat: 34.772595,
      lng: 137.692268
    },
    {
      images: "https://www.hana300.com/azami032.jpg",
      lat: 34.776089,
      lng: 137.695476
    },
    {
      images: "https://www.hana300.com/ajisai20.jpg",
      lat: 34.776128,
      lng: 137.691142
    }
];



export default class MyPage extends Component {
    render() {
        return (
            <>
                <Navigation {...this.props}></Navigation>
                <Header></Header>
                <div className="divider-header"></div>
                <NameMember />
                <TabsSearch />
                <Map locations={locations} zoom={13} center={{ lat: 34.753171, lng: 137.682814 }}/>
                <Footer></Footer>
            </>
        )
    }
}
