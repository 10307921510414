import React, { Component } from "react";

import Navigation from "components/Navigation";
import Header from "components/Header";
import Footer from "components/Footer";

import "../assets/scss/pages/policy.scss";
import { Container } from "react-bootstrap";

export default class TermsOfService extends Component {
  render() {
    return (
      <>
        <Navigation {...this.props}></Navigation>
        <Header></Header>
        <div className="divider-header"></div>
        <section id="policy">
          <Container>
            <h3>ご利用規約</h3>
            <p>
              [COMPANY
              NAME]（以下「弊社」という）は、弊社の事業を通じて取得した個人情報について適正な利用と管理を図るべく、下記の通り、弊社の個人情報保護方針を定め、これを実行・維持いたします。
            </p>
            <div class="boxContent">
              <div class="item">
                <h5 class="heading">1. 基本姿勢</h5>
                <div class="text">
                  <p>
                    弊社は、この方針を実行するために、個人情報の取り扱いに関する社内規程および体制を定め、全てのスタッフに周知徹底し、適切な運用がなされるよう維持し、改善してまいります。
                  </p>
                </div>
              </div>
              <div class="item">
                <h5 class="heading">2. 適正な取得</h5>
                <div class="text">
                  <ul>
                    <li>
                      弊社は、個人情報の取得にあたり、適法かつ公正な手段によってこれを行い、個人情報の利用目的等の必要事項について取得時に明示をします。
                    </li>
                    <li>
                      弊社は、個人情報を間接的に取得する場合、弊社ウェブサイト等に個人情報の利用目的等の必要事項を告知します。
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item">
                <h5 class="heading">3. 利用目的の遵守</h5>
                <div class="text">
                  <ul>
                    <li>
                      弊社は、個人情報を利用するにあたっては、利用目的の範囲内でのみ利用することとし、その目的の範囲を超えた利用はいたしません。
                    </li>
                    <li>
                      弊社は、利用目的の範囲を超えて個人情報を利用する場合には、ご本人にその旨を事前に通知し、ご本人の同意を得ることとします。
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item">
                <h5 class="heading">4. 安全管理</h5>
                <div class="text">
                  <p>
                    弊社は、個人情報等の漏えい、滅失又はき損等を防止するため、適切な安全管理対策を行うとともに、必要な是正措置を講じます。
                  </p>
                </div>
              </div>
              <div class="item">
                <h5 class="heading">5. 正確性の確保</h5>
                <div class="text">
                  <p>
                    弊社は、個人情報を保有する場合、正確、安全かつ最新の状態に保つよう、適切な措置を講じます。
                  </p>
                </div>
              </div>
              <div class="item">
                <h5 class="heading">6. 第三者への提供</h5>
                <div class="text">
                  <p>
                    弊社は、お客様にご同意いただいた場合を除き、第三者に対し、個人情報を開示・提供することはいたしません。
                  </p>
                </div>
              </div>
              <div class="item">
                <h5 class="heading">7. 委託先の監督</h5>
                <div class="text">
                  <p>
                    弊社は、個人情報等の取り扱いを委託する場合、委託先にて個人情報等の安全管理が図られるよう適切かつ厳重に監督します。
                  </p>
                </div>
              </div>
              <div class="item">
                <h5 class="heading">8. 保有個人データの開示・訂正等</h5>
                <div class="text">
                  <p>
                    弊社は、保有個人デ－タの開示、訂正、利用停止等の求めに対し、本人確認のうえ、速やかに対応いたします。
                  </p>
                </div>
              </div>
              <div class="item">
                <h5 class="heading">9. 法令の遵守</h5>
                <div class="text">
                  <p>
                    弊社は、個人情報の保護に関する法律、その他国が定める指針およびその他の規範を遵守するものとします。
                  </p>
                </div>
              </div>
            </div>
            <p>最終更新日：2021年9月7日</p>
          </Container>
        </section>
        <Footer></Footer>
      </>
    );
  }
}
