import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./navigation.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import Form from 'react-bootstrap/Form'

import IconProfile from "assets/images/setting/profile.svg";
import IconNotification from "assets/images/setting/notification.svg";
import IconContactUs from 'assets/images/setting/contact-us.svg'
import IconTerms from 'assets/images/setting/terms-of-service.svg'
import IconPolicy from 'assets/images/setting/privacy-policy.svg'
import IconLogout from 'assets/images/setting/logout.svg'

export default function Navigation(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getNavLinkClass = (path) => {
    return props.location.pathname === path ? " active" : "";
  };

  return (
    <section id="navigation">
      <nav>
        <ul>
          <li className={`nav-item${getNavLinkClass("/")}`}>
            <Button className="nav-link" href="/" variant="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.723"
                height="17.937"
                viewBox="0 0 16.723 17.937"
              >
                <g transform="translate(46.441 -7.375)">
                  <path
                    d="M8.362,7.375,0,13.974V25.312H6.585V20.343h3.553v4.968h6.585V13.974Z"
                    transform="translate(-46.441)"
                  />
                </g>
              </svg>
              <span>ホーム</span>
            </Button>
          </li>
          <li className={`nav-item${getNavLinkClass("/mypage")}`}>
            <Button className="nav-link" href="/mypage" variant="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.873"
                height="21.873"
                viewBox="0 0 18.873 21.873"
              >
                <g transform="translate(-123.234 -89.767)">
                  <path
                    d="M137.173,248.432a7.851,7.851,0,0,1-9.006,0,9.358,9.358,0,0,0-4.443,4.134c-1.111,1.976-.24,4.77,1.682,4.77h14.529c1.922,0,2.793-2.794,1.681-4.77A9.351,9.351,0,0,0,137.173,248.432Z"
                    transform="translate(0 -145.696)"
                  />
                  <circle
                    cx="5.731"
                    cy="5.731"
                    r="5.731"
                    transform="translate(126.111 91.565) rotate(-9.023)"
                  />
                </g>
              </svg>
              <span>マイページ</span>
            </Button>
          </li>
          <li className={`nav-item${getNavLinkClass("/upload")}`}>
            <Button className="nav-link" href="/upload" variant="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.654"
                height="19.953"
                viewBox="0 0 25.654 19.953"
              >
                <path
                  d="M196.958,224.828a3.143,3.143,0,1,0,3.114,3.143A3.128,3.128,0,0,0,196.958,224.828Z"
                  transform="translate(-184.131 -216.413)"
                />
                <path
                  d="M23.989,60.841H21.052a1.664,1.664,0,0,1-1.518-.991l-.879-1.97a1.666,1.666,0,0,0-1.519-.99H8.519A1.668,1.668,0,0,0,7,57.88l-.878,1.97a1.664,1.664,0,0,1-1.518.991H1.665A1.673,1.673,0,0,0,0,62.521V75.164a1.673,1.673,0,0,0,1.665,1.679H23.989a1.673,1.673,0,0,0,1.665-1.679V62.521A1.673,1.673,0,0,0,23.989,60.841ZM12.827,74.275A5.828,5.828,0,1,1,18.6,68.447,5.8,5.8,0,0,1,12.827,74.275Z"
                  transform="translate(0 -56.89)"
                />
              </svg>
              <span>花を登録</span>
            </Button>
          </li>
          <li className={`nav-item${getNavLinkClass("/member")}`}>
            <Button className="nav-link" href="/member" variant="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27.494"
                height="21.246"
                viewBox="0 0 27.494 21.246"
              >
                <g transform="translate(-1092.176 -849.878)">
                  <g transform="translate(1104.494 849.878)">
                    <path
                      d="M134.443,248.432a6.313,6.313,0,0,1-7.242,0,7.525,7.525,0,0,0-3.573,3.324c-.893,1.589-.193,3.835,1.353,3.835h11.683c1.546,0,2.246-2.247,1.352-3.835A7.519,7.519,0,0,0,134.443,248.432Z"
                      transform="translate(-123.234 -238.003)"
                    />
                    <ellipse
                      cx="4.609"
                      cy="4.609"
                      rx="4.609"
                      ry="4.609"
                      transform="translate(2.313 1.446) rotate(-9.023)"
                    />
                  </g>
                  <g transform="translate(1092.699 850.826)">
                    <path
                      d="M136.455,248.432a7.446,7.446,0,0,1-8.542,0,8.876,8.876,0,0,0-4.214,3.921c-1.054,1.874-.228,4.524,1.6,4.524h13.78c1.823,0,2.649-2.65,1.595-4.524A8.869,8.869,0,0,0,136.455,248.432Z"
                      transform="translate(-123.234 -237.08)"
                      stroke="#fff"
                      stroke-width="1"
                    />
                    <ellipse
                      cx="5.436"
                      cy="5.436"
                      rx="5.436"
                      ry="5.436"
                      transform="translate(2.729 1.705) rotate(-9.023)"
                      stroke="#fff"
                      stroke-width="1"
                    />
                  </g>
                </g>
              </svg>
              <span>花とも</span>
            </Button>
          </li>
          <li className={`nav-item${getNavLinkClass("/setting")}`}>
            <Button
              className="nav-link"
              href="#"
              variant=""
              onClick={handleShow}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.248"
                height="22.248"
                viewBox="0 0 22.248 22.248"
              >
                <g transform="translate(-0.004)">
                  <path d="M21.707,9.125,19.28,9.013a.57.57,0,0,1-.5-.352l-.5-1.2a.573.573,0,0,1,.107-.6l1.637-1.8A.573.573,0,0,0,20,4.267L17.986,2.249a.573.573,0,0,0-.791-.018L15.4,3.868a.572.572,0,0,1-.605.106l-1.2-.5a.57.57,0,0,1-.352-.5L13.127.546A.572.572,0,0,0,12.556,0H9.7a.573.573,0,0,0-.572.546L9.017,2.972a.57.57,0,0,1-.352.5l-1.2.5a.571.571,0,0,1-.605-.106l-1.8-1.637a.573.573,0,0,0-.791.018L2.253,4.267a.572.572,0,0,0-.018.79l1.637,1.8a.571.571,0,0,1,.1.6l-.5,1.2a.571.571,0,0,1-.5.352L.55,9.125A.572.572,0,0,0,0,9.7v2.855a.573.573,0,0,0,.546.571l2.426.112a.571.571,0,0,1,.5.352l.5,1.2a.571.571,0,0,1-.1.6l-1.637,1.8a.573.573,0,0,0,.018.791L4.272,20a.573.573,0,0,0,.79.018l1.8-1.637a.572.572,0,0,1,.605-.106l1.2.5a.571.571,0,0,1,.352.5L9.129,21.7a.573.573,0,0,0,.572.546h2.855a.572.572,0,0,0,.571-.546l.113-2.426a.571.571,0,0,1,.352-.5l1.2-.5a.573.573,0,0,1,.605.106l1.8,1.637A.574.574,0,0,0,17.985,20L20,17.981a.574.574,0,0,0,.018-.791l-1.637-1.8a.571.571,0,0,1-.107-.6l.5-1.2a.57.57,0,0,1,.5-.352l2.427-.112a.572.572,0,0,0,.545-.571V9.7A.571.571,0,0,0,21.707,9.125ZM11.128,14.758a3.633,3.633,0,1,1,3.633-3.633A3.634,3.634,0,0,1,11.128,14.758Z" />
                </g>
              </svg>
              <span>設定</span>
            </Button>
            <Offcanvas show={show} onHide={handleClose} placement="bottom">
              <Offcanvas.Body>
                <nav className="nav flex-colum">
                  <ul>
                    <li>
                    <Nav.Link href="/editprofile">
                    <div className="text">
                      <img src={IconProfile} alt="" />
                      <span>プロフィール編集</span>
                    </div>
                  </Nav.Link>
                    </li>
                    <li>
                    <div className="text">
                      <img src={IconNotification} alt="" />
                      <span>通知</span>
                    </div>
                    <div className="switch">
                    <Form>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                      />
                    </Form>
                    </div>
                    </li>
                    <li>
                    <Nav.Link href="/editprofile">
                    <div className="text">
                      <img src={IconContactUs} alt="" />
                      <span>お問合せ</span>
                    </div>
                  </Nav.Link>
                    </li>
                    <li>
                    <Nav.Link href="/terms-of-service">
                    <div className="text">
                      <img src={IconTerms} alt="" />
                      <span>ご利用規約</span>
                    </div>
                  </Nav.Link>
                    </li>
                    <li>
                    <Nav.Link href="/privacy-policy">
                    <div className="text">
                      <img src={IconPolicy} alt="" />
                      <span>個人情報保護方針</span>
                    </div>
                  </Nav.Link>
                    </li>
                    <li>
                    <Nav.Link href="/logout">
                    <div className="text">
                      <img src={IconLogout} alt="" />
                      <span>ログアウト</span>
                    </div>
                  </Nav.Link>
                    </li>
                  </ul>
                </nav>
              </Offcanvas.Body>
            </Offcanvas>
          </li>
        </ul>
      </nav>
    </section>
  );
}
